<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <station-form></station-form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import stationForm from "@/view/project/stations/components/StationForm.vue";
import customBreadcrumb from "@/view/custom/breadcrumb";

export default {
  name: "stations.create",
  components: {
    stationForm,
    customBreadcrumb
  },
  data(){
    return{
      customBreadcrumb:{
        title: "Santraller",
        type: "Ekle",
        pathName: null,
      }
    }
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Santraller", route: "/stations", icon: "flaticon-car" },
      { title: "Ekle" },
    ]);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <v-alert color="cyan" border="left" elevation="2" colored-border>
        <v-form ref="form" v-model="isFormValid" class="w-100">
          <div class="row">
            <div class="col-12 mb-4">
              <div class="card">
                <div class="card-header cyan">
                  <h2 class="text-white">
                    Genel Bilgiler
                    <a @click="showMainInfo = !showMainInfo">
                      <i
                        v-if="showMainInfo"
                        class="fas fa-chevron-down text-white"
                      ></i>
                      <i v-else class="fas fa-chevron-right text-white"></i>
                    </a>
                  </h2>
                </div>
                <div class="card-body" v-show="showMainInfo">
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="name"
                        :counter="255"
                        :rules="name_rules"
                        label="Santral Adı"
                        autocomplete="false"
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="selected_station_type.power_station_type_id"
                        :items="power_station_type_ids"
                        item-value="power_station_type_id"
                        item-text="text"
                        label="Güç Santrali Tipi"
                        :rules="power_station_type_id_rules"
                        autocomplete="false"
                      ></v-select>
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="selected_license.license_status"
                        :items="license_statuses"
                        item-value="license_status"
                        item-text="text"
                        label="Lisans"
                        :rules="license_status_rules"
                      ></v-select>
                    </div>
                    <div class="col-6">
                      <v-text-field
                        v-model="installed_power"
                        :rules="installed_power_rules"
                        label="Kurulu Güç"
                        type="number"
                        step="any"
                        autocomplete="false"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="card">
                <div class="card-header cyan">
                  <h2 class="text-white">
                    Konum Bilgileri
                    <a @click="showLocationInfo = !showLocationInfo">
                      <i
                        v-if="showLocationInfo"
                        class="fas fa-chevron-down text-white"
                      ></i>
                      <i v-else class="fas fa-chevron-right text-white"></i>
                    </a>
                  </h2>
                </div>
                <div class="card-body" v-show="showLocationInfo">
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="latitude"
                        label="Enlem"
                        type="number"
                        step="any"
                        autocomplete="false"
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-text-field
                        v-model="longitude"
                        label="Boylam"
                        type="number"
                        step="any"
                        autocomplete="false"
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-text-field
                        v-model="altitude"
                        label="Rakım"
                        type="number"
                        step="any"
                        autocomplete="false"
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-text-field
                        v-model="area"
                        :rules="area_rules"
                        label="Alan (m2)"
                        type="number"
                        step="any"
                        autocomplete="false"
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="selected_cities.city_id"
                        :items="cities"
                        item-value="city_id"
                        item-text="text"
                        label="İl"
                        :rules="city_id_rules"
                        @change="cityChanged"
                      ></v-select>
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="selected_counties.county_id"
                        :items="counties"
                        item-value="county_id"
                        item-text="text"
                        label="İlçe"
                        :rules="county_id_rules"
                        @change="countyChanged"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <defined-device-form
              :selected_devices="selected_devices_default"
              @changeSelectedDevices="changeSelectedDevices"
            ></defined-device-form>
            <div class="col-12 text-center">
              <v-btn
                :disabled="!isFormValid"
                class="mr-4 bg-success"
                @click="save"
              >
                Kaydet
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-alert>
    </div>
  </div>
</template>

<style scoped>
.card-header {
  padding: 1rem 1.25rem;
}
.fa-chevron-down {
  font-size: 1.45rem;
}
.fa-chevron-right {
  font-size: 1.45rem;
}
</style>

<script>
import {
  STORE_STATION,
  UPDATE_STATION,
} from "@/core/services/store/stations.module";
import { GET_STATION_TYPES } from "@/core/services/store/stationTypes.module";
import {
  GET_CITIES,
  GET_COUNTIES,
} from "@/core/services/store/location.module";
import DefinedDeviceForm from "@/view/custom/device/DefinedDeviceForm";
export default {
  name: "station-form",
  components: { DefinedDeviceForm },
  data() {
    return {
      id: "",
      /*Selects*/
      //Power Station Type
      selected_station_type: { power_station_type_id: 1, text: null },
      power_station_type_ids: [],
      //License
      selected_license: { license_status: null, text: null },
      license_statuses: [
        { license_status: "Lisanslı", text: "Lisanslı" },
        { license_status: "Lisanssız", text: "Lisanssız" },
      ],
      //City
      selected_cities: { city_id: null, text: null },
      cities: [],
      allCities: [],
      //County
      selected_counties: { county_id: null, text: null },
      counties: [],
      allCounties: [],
      //Form Validation Rules
      isFormValid: false,
      name: "",
      name_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => (v && v.length <= 255) || "Ad 255 karakterden az olmalıdır.",
      ],
      latitude: "",
      // latitude_rules: [
      //     v => !!v || "Boş bırakılamaz",
      //     v => (!isNaN(parseFloat(v))) || 'Sayı olmalıdır.',
      // ],
      longitude: "",
      // longitude_rules: [
      //     v => !!v || "Boş bırakılamaz",
      //     v => (!isNaN(parseFloat(v))) || 'Sayı olmalıdır.',
      // ],
      altitude: "",
      // altitude_rules: [
      //     v => !!v || "Boş bırakılamaz",
      //     v => (!isNaN(parseFloat(v))) || 'Sayı olmalıdır.',
      // ],
      license_status: "",
      license_status_rules: [(v) => !!v || "Boş bırakılamaz"],
      city_id: "",
      city_id_rules: [(v) => !!v || "Boş bırakılamaz"],
      county_id: "",
      county_id_rules: [(v) => !!v || "Boş bırakılamaz"],
      installed_power: "",
      installed_power_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => !isNaN(parseFloat(v)) || "Sayı olmalıdır.",
      ],
      area: "",
      area_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => !isNaN(parseFloat(v)) || "Sayı olmalıdır.",
      ],
      power_station_type_id: "",
      power_station_type_id_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => !isNaN(parseFloat(v)) || "Sayı olmalıdır.",
      ],
      //Bölümler başlangıçta gözükür gelecekler
      showMainInfo: true,
      showLocationInfo: true,
      selected_devices: [],
      selected_devices_default: [],
    };
  },
  methods: {
    save() {
      let id = this.id;
      if (id != "") {
        this.update();
      } else {
        this.new();
      }
    },
    new() {
      let name = this.name;
      let latitude = this.latitude;
      let longitude = this.longitude;
      let altitude = this.altitude;
      let license_status = this.selected_license.license_status;
      this.selected_devices.sort((a, b) => (parseInt(a.split(':')[1]) > parseInt(b.split(':')[1]) ? 1 : -1));
      let selected_devices = JSON.stringify(this.selected_devices);
      let city_id = this.selected_cities.city_id;
      let county_id = this.selected_counties.county_id;
      let power_station_type_id =
        this.selected_station_type.power_station_type_id;
      let installed_power = this.installed_power;
      let area = this.area;

      this.$store
        .dispatch(STORE_STATION, {
          name,
          latitude,
          longitude,
          altitude,
          license_status,
          city_id,
          county_id,
          installed_power,
          area,
          power_station_type_id,
          selected_devices,
        })
        .then(() => {
          this.$router.push({ name: "stations" });
        });
    },
    update() {
      let name = this.name;
      let latitude = this.latitude;
      let longitude = this.longitude;
      let altitude = this.altitude;
      let license_status = this.selected_license.license_status;
      this.selected_devices.sort((a, b) => (parseInt(a.split(':')[1]) > parseInt(b.split(':')[1]) ? 1 : -1));
      let selected_devices = JSON.stringify(this.selected_devices);
      let city_id = this.selected_cities.city_id;
      let county_id = this.selected_counties.county_id;
      let power_station_type_id =
        this.selected_station_type.power_station_type_id;
      let installed_power = this.installed_power;
      let area = this.area;
      let id = this.id;

      this.$store
        .dispatch(UPDATE_STATION, {
          id,
          name,
          latitude,
          longitude,
          altitude,
          license_status,
          city_id,
          county_id,
          installed_power,
          area,
          power_station_type_id,
          selected_devices,
        })
        .then(() => {
          this.$router.push({ name: "stations.show", params: { id } });
        });
    },
    //Şehir Seçimi
    cityChanged(city_code) {
      const result = this.allCounties.filter((el) => {
        return el.city_id == city_code;
      });
      this.counties = result;
      this.selected_counties = { county_id: result[0].county_id, text: null };
    },
    //İlçe Seçimi
    countyChanged(county_code) {
      const result = this.allCounties.filter((el) => {
        return el.county_id == county_code;
      });
      this.selected_cities = { city_id: result[0].city_id, text: null };
    },
    changeSelectedDevices(devices){
      this.selected_devices = devices;
    }
  },
  created() {
    //Şehirleri Getirme
    this.$store.dispatch(GET_CITIES).then((res) => {
      res.forEach((el) => {
        let data = { city_id: el.id, text: el.city };
        this.allCities.push(data);
        this.cities.push(data);
      });
    });
    //İlçeleri Getirme
    this.$store.dispatch(GET_COUNTIES).then((res) => {
      res.forEach((el) => {
        let data = { county_id: el.id, text: el.county, city_id: el.city_id };
        this.allCounties.push(data);
        this.counties.push(data);
      });
    });
    //Güç Santral Tipleri Getirme
    this.$store.dispatch(GET_STATION_TYPES).then((res) => {
      res.forEach((el) => {
        let data = { power_station_type_id: el.id, text: el.name };
        this.power_station_type_ids.push(data);
      });
    });
  },
};
</script>